import { isDEVMODE, viewStorage } from '../_globals'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { Observer } from 'gsap/Observer'
gsap.registerPlugin([ScrollTrigger, Observer])

export default class Application {

  constructor(container) {

    this.DOM = { 
      container: container,
      visual : container.querySelector('.Application__visual'),
      mobile : container.querySelector('.Application__visual .mobile__visual img'),
      bg : container.querySelector('.Application__visual .background')
    }

    this.init()

  }

  init() {

    if (isDEVMODE) console.log('Init Application')

    const { container, visual, mobile } = this.DOM
    
    this.xTo = gsap.quickTo(mobile, "x", {duration: 1, ease: "power4"}),
    this.yTo = gsap.quickTo(mobile, "y", {duration: 1, ease: "power4"});
    this.onMove = this.onMove.bind(this)

    this.observer = Observer.create({
      target: window, // can be any element (selector text is fine)
      type: "pointer,touch", // comma-delimited list of what to listen for ("wheel,touch,scroll,pointer")
      onMove: this.onMove
    });

    this.ST = ScrollTrigger.create({
      trigger: visual,
      toggleClass: '--visible',
      markers: isDEVMODE,
      onEnter: () => this.observer.enable(),
      onEnterBack: () => this.observer.enable(),
      onLeave: () => this.observer.disable(),
      onLeaveBack: () => this.observer.disable(),
      onToggle: ({direction}) => {
        if (direction > 0) {
          visual.classList.add('from--btm')
          visual.classList.remove('from--top')
        } else {
          visual.classList.add('from--top')
          visual.classList.remove('from--btm')
        }
      }
    })

  }

  onMove(e) {
    const { x, y } = e
    this.xTo((window.innerWidth * 0.5 - x) * 0.05);
    this.yTo((window.innerHeight * 0.5 - y) * 0.05);
  }

  destroy() {

    if (isDEVMODE) console.log('Destroy Application')
    this.observer && this.observer.kill()
    this.ST && this.ST.kill()

  }
}
