import { globalStorage, viewStorage, domStorage } from '../_globals'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

export const GlobalIntro = () => {
  const { intro, body, header } = domStorage
  const { current, viewScroll } = viewStorage
  
  const isHome = (current.dataset.taxiView === 'home')
  console.log('GlobalIntro')
  console.log(isHome)

  const introTL = gsap.timeline({
    defaults: { duration: 1, ease: 'power3.inOut' },
    onStart: () => {
      viewScroll && viewScroll.scroll.stop()
      body.classList.remove('--preloading')
      body.classList.remove('--loading')
    },
    onComplete: () => {
      ScrollTrigger.refresh()
      body.classList.remove('--animating')
      body.classList.remove('--intro')

      if (!isHome) viewScroll && viewScroll.scroll.start()

      // Remove Loader
      body.removeChild(intro)

      // Start Scroll
      globalStorage.firstLoad = false
    },
    delay: 1
  })
  .to(intro, { opacity: 0 })

  if (!isHome) {
    introTL.from(header.querySelector('.Brand'), { yPercent: -100, opacity: 0, ease: 'power4.out', duration: 2 }, '<')
    .from(header.querySelector('.Brand span'), { yPercent: -100, ease: 'power4.out', duration: 2 }, '<')
    .from(header.querySelector('.Btn'), { yPercent: -200, ease: 'power4.out', duration: 1.6 }, '<') 
  }

}