import { isDEVMODE, globalStorage, domStorage, viewStorage } from '../_globals'
import { gsap } from 'gsap'
import { SplitText } from 'gsap/SplitText'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(SplitText, ScrollTrigger)

export default class Hero {

  constructor(container) {

    this.DOM = {
      container: container,
      video : container.querySelector('.Hero__video'),
      steps : container.querySelectorAll('.Hero__step'),
      paintingZoom : container.querySelector('.Hero__painting'),
      painting : container.querySelector('.Hero__bg .painting'),
      visual : container.querySelectorAll('.Hero__bg .wrapper'),
    }

    this.init()

  }

  init() {

    if (isDEVMODE) console.log('Init Hero')

    const { container, video, steps, visual, painting } = this.DOM
    const { header } = domStorage

    // SetupTimeline
    this.heroTL = gsap.timeline({
      defaults: { 
        ease: 'none' 
      },
      scrollTrigger: {
        trigger: container,
        invalidateOnRefresh: true,
        pin: true,
        start: 'top top',
        end: '+=2500',
        scrub: 1
      }
    })

    const isMacOS = globalStorage.os.name === 'osx'
    
    const StepsTL = gsap.timeline()
    steps.forEach((step, i) => {
      step.ST = new SplitText(step.querySelector('.sentence, p'), {type: ["lines", "words"], linesClass: '--line'})
      if (i !== 0 ) StepsTL.fromTo(step.ST.words, { yPercent: 100, skewX: -10, opacity: 0 }, { yPercent: 0, opacity: 1, skewX: 0, duration: 2, ease: 'power4.out', stagger: 0.03 })
      if (i < steps.length - 1 ) StepsTL.fromTo(step.ST.words, { yPercent: 0, skewX: 0, opacity: 1 }, { yPercent: -100, skewX: 10, opacity: 0, duration: 1, ease: 'power4.in', stagger: 0.03 })
    })

    const stepsAnimationDuration = steps.length * 3

    this.heroTL.fromTo(painting.querySelector('img'), { 
      scale: 1.5,
      yPercent: -15,
    }, {
      scale: 1,
      yPercent: -0,
      duration: stepsAnimationDuration, 
      ease: 'power4.inOut' 
    })

    // this.heroTL.fromTo(visual, { scale: () => window.innerWidth > 768 ? (window.innerWidth / painting.offsetWidth) : 5 }, { scale: 1, duration: steps.length * 3 + 1, ease: 'power4.inOut' }, `-=2`)
    this.heroTL.fromTo(visual, { 
      scale: 6,
    }, { 
      scale: 1, 
      duration: stepsAnimationDuration, 
      ease: isMacOS ? 'power4.inOut' : 'power.0'
    }, `<`)

    this.heroTL.fromTo(painting, { 
      scale: 1,
    }, {
      scale: 0.16,
      duration: stepsAnimationDuration, 
      ease: isMacOS ? 'power4.inOut' : 'power.0'
    }, `<`)

    this.heroTL.add(StepsTL, `<`)

    // onLeave
    this.heroLeave = gsap.timeline({
      scrollTrigger: {
        trigger: container,
        start: 'bottom bottom',
        end: 'bottom top',
        scrub: true,
        markers: isDEVMODE
      }
    })

    this.heroLeave.to(container.querySelector(':scope > .wrapper'), { yPercent: 40, ease: 'none' })


    // Play Video
    const onVideoEnded = () => this.onEnterTL.play()
    video.querySelector('video').addEventListener('ended', onVideoEnded ,false)
    video.querySelector('video').load()

    const { viewScroll } = viewStorage
    viewScroll && viewScroll.scroll.stop()


    // OnEnter
    this.onEnterTL = gsap.timeline({
      paused: true,
      defaults: { duration: 1, ease: 'power3.inOut' },
      onComplete: () => {
        ScrollTrigger.refresh()

        container.removeChild(video)
  
        // Start Scroll
        const { viewScroll } = viewStorage
        viewScroll && viewScroll.scroll.start()
      }
    })
    .to(video, { opacity: 0 })
    .from(steps[0].ST.words, { yPercent: 100, skewX: -10, opacity: 0, ease: 'power4.out', duration: 2, stagger: 0.08 }, '<')
    .from(painting.querySelector('.visual__container'), { scale: 1.6, ease: 'power4.out', duration: 2.4 }, '<')
    .from(header, { opacity: 0, ease: 'power4.out', duration: 1.2 }, '<')
    .from(header.querySelector('.Brand'), { yPercent: -100, ease: 'power4.out', duration: 2 }, '<')
    .from(header.querySelector('.Brand span'), { yPercent: -100, ease: 'power4.out', duration: 2 }, '<')
    .from(header.querySelector('.Btn'), { yPercent: -200, ease: 'power4.out', duration: 1.6 }, '<') 

  }

  destroy() {

    if (isDEVMODE) console.log('Destroy Hero')
    this.heroTL.kill()
    this.heroLeave.kill()

  }
}
