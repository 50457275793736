import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import Hero from '../modules/hero'
import Application from '../modules/application'
import WknSwiper from '../modules/wkn-swiper'

export default class HomePageRenderer extends Renderer {

  initialLoad() {

    const onDOMContentLoaded = () => {
      document.removeEventListener('DOMContentLoaded', onDOMContentLoaded)
      this.onEnter()
    }

    document.addEventListener('DOMContentLoaded', onDOMContentLoaded)

  }

  onEnter() {
    this.init()
  }

  init() {
    if (isDEVMODE) console.log(`Init Page : Homepage`)
    if (isDEVMODE) console.log(viewStorage.current)
      
    this.DOM = {
      hero : viewStorage.current.querySelector('.Hero'),
      formules : viewStorage.current.querySelector('.Formules'),
      advantages : viewStorage.current.querySelector('.Advantages'),
      application : viewStorage.current.querySelector('.Application')
    }

    this.DOM.hero && this.initHero()
    this.DOM.formules && this.initFormules()
    this.DOM.advantages && this.initAdvantages()
    this.DOM.application && this.iniApplication()
  }

  initHero() {
    const { hero } = this.DOM
    this.hero = new Hero(hero)
  }

  initFormules() {
    const { formules } = this.DOM
    this.formulesSwiper = new WknSwiper(formules.querySelector('.swiper'), {
      slidesPerView : 1,
      spaceBetween: 10,
      breakpoints: {
        768: {
          slidesPerView : 3,
          spaceBetween: 20,
        }
      }
    })
  }

  initAdvantages() {
    const { advantages } = this.DOM
    this.advantagesSwiper = new WknSwiper(advantages.querySelector('.swiper'), {
      slidesPerView : 'auto',
      centeredSlides: true,
      spaceBetween: 0
    })
  }

  iniApplication() {
    const { application } = this.DOM
    this.application = new Application(application)
  }

  // onEnterCompleted() {}

  // onLeave() {}

  onLeaveCompleted() {
    this.hero && this.hero.destroy()
    this.formulesSwiper && this.formulesSwiper.destroy()
    this.advantagesSwiper && this.advantagesSwiper.destroy()
  }
}
