import { isDEVMODE, viewStorage } from '../_globals'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { SplitText } from 'gsap/SplitText'
gsap.registerPlugin(ScrollTrigger, SplitText)

export default class textReveal {

  constructor(container = viewStorage.current) {

    this.DOM = {
      items: container.querySelectorAll('*[data-reveal]')
    }

    if (this.DOM.items.length === 0) return

    viewStorage.hasTextReveal = true

    setTimeout(() => this.init(), 500)

  }

  init() {

    if (isDEVMODE) console.log('Init textReveal')

    this.isHorizontal = false

    ScrollTrigger.defaults({
      markers: isDEVMODE
    })

    this.DOM.items.forEach((el) => {

      const titles = el.querySelectorAll(':scope h2, :scope h3')
      const textes = el.querySelectorAll(':scope p')
      const btn = el.querySelector('.Btn')

      el.STTitles = new SplitText(titles, {type: ["words"]})
      el.STTextes = new SplitText(textes, {type: ["words"]})
      el.TL = gsap.timeline({
        defaults: { 
          ease: 'none' 
        },
        scrollTrigger: {
          trigger: el,
          toggleClass: '--visible',
          once: false,
          toggleActions: "restart none none reset",
          start: 'top bottom-=10%',
          end: 'bottom top'
        },
        onComplete: () => {
          el.STTitles.revert()
          el.STTextes.revert()
        }
      })

      el.TL.from(el.STTitles.words, { yPercent: 100, skewX: -10, opacity: 0, duration: 2, ease: 'power4.out', stagger: 0.03 }, 0)
      el.TL.from(el.STTextes.words, { yPercent: 100, skewX: -10, opacity: 0, duration: 2.4, ease: 'power4.out', stagger: { each: 0.005, from: 'random' }}, 0.25)
      btn && el.TL.from(btn, { yPercent: 100, skewX: -10, opacity: 0, duration: 2, ease: 'power4.out' }, '<')
    
    })

  }

  destroy() {

    if (isDEVMODE) console.log('Destroy textReveal')

    this.DOM.items.forEach((el) => {
      if (viewStorage.current.contains(el)) {
        el.TL.kill()
      }
    })

    viewStorage.hasTextReveal = false

  }
}
