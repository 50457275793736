import { isDEVMODE, globalStorage, domStorage } from '../_globals'
import { gsap } from 'gsap'
import { Observer } from 'gsap/Observer'
gsap.registerPlugin([Observer])

export default class Header {
  constructor() {
    this.DOM = { el: domStorage.header }
    /* this.DOM.brand = this.DOM.el.querySelector('.brand')
    this.DOM.toggle = this.DOM.el.querySelector('.Nav__toggle')
    this.DOM.menuContainer = this.DOM.el.querySelector('.Nav__container')*/

    this.setEvents()
  }

  setEvents() {

    this.onScroll = this.onScroll.bind(this)

    this.observer = Observer.create({
      target: window, // can be any element (selector text is fine)
      type: "scroll", // comma-delimited list of what to listen for ("wheel,touch,scroll,pointer")
      onChangeY: this.onScroll,
      onDown: () => this.DOM.el.classList.remove('--show'),
      onUp: () => this.DOM.el.classList.add('--show'),
    });

    /*const { overlay } = domStorage
    const { toggle } = this.DOM

    // Toggle
    toggle.addEventListener('click', (e) => {
      e.preventDefault()
      globalStorage.menuOpen === true ? this.close() : this.open()
    })

    globalStorage.openMobileMenu = () => this.open()
    globalStorage.closeMobileMenu = () => this.close()

    // overlay
    overlay.addEventListener('click', (e) => {
      if (globalStorage.menuOpen) this.close()
    })

    // Resize
    this.resizeTimeout = setTimeout(() => {}, 0)
    this.onResize = this.onResize.bind(this)
    this.windowWidthResize = window.innerWidth
    window.addEventListener('resize', this.onResize)*/

  }

  onScroll(e) {
    const { scrollY } = e
    if(scrollY() >= 100) this.DOM.el.classList.add('--fixed')
    if(scrollY() < 100) {
      this.DOM.el.classList.remove('--fixed')
      this.DOM.el.classList.remove('--show')
    }
  }

  /*open() {
    // if (isDEVMODE) console.log('Open Menu')

    globalStorage.menuOpen = true
    domStorage.body.classList.add('showMenu')
    domStorage.body.style.overflow = 'hidden'

  }

  close() {
    // if (isDEVMODE) console.log('Close Menu')

    globalStorage.menuOpen = false
    domStorage.body.classList.remove('showMenu')
    domStorage.body.style.overflow = ''
  }

  onResize() {
    clearTimeout(this.resizeTimeout)
    this.resizeTimeout = setTimeout(() => {
      if (this.windowWidthResize !== window.innerWidth) {
        // if (isDEVMODE) console.log('updateHeaderResize')
        this.windowWidthResize = window.innerWidth
        if (globalStorage.menuOpen === true) this.close()
      }
    }, 250)
  }*/
}
