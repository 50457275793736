/* global gtag */

/* --- Load Plugins / Functions --- */
import { isDEVMODE, globalStorage, viewStorage, domStorage } from './_globals'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { GlobalIntro } from './animations/global-intro'

import modalContact from './modules/modal-contact.js'
import viewScroll from './modules/view-scroll.js'
import viewPrllx from './modules/view-prllx.js'
import viewInView from './modules/view-inview.js'
import viewTextReveal from './modules/view-text-reveal'
import {FormsManager} from "@wokine-agency/forms";
import Manager from '@wokine-agency/forms/src/Manager'

/* --- DOMContentLoaded Function --- */
export const onReady = () => {

  const { body } = domStorage
  initForms({
    DOM: domStorage.body
  })

  viewStorage.viewScroll = new viewScroll()
  viewStorage.viewPrllx = new viewPrllx(body)
  viewStorage.viewInView = new viewInView(body)
  viewStorage.viewTextReveal = new viewTextReveal(body)
  viewStorage.modalContact = new modalContact(body)

  setTimeout(() => window.scrollTo(globalStorage.pageScrollTop, globalStorage.pageScrollTop), 100)
  ScrollTrigger.refresh()

  // Intro
  GlobalIntro()

}

export const onEnter = (to, trigger) => {

  // LoadingClasses
  const { body } = domStorage
  body.classList.remove('--loading')

  // ScrollTop
  window.scrollTo(globalStorage.pageScrollTop, globalStorage.pageScrollTop)

  // Update Current View
  viewStorage.current = to.renderer.content

  // Add Page Effects
  const { hasSmoothScroll } = viewStorage
  body.style.overflow = ''

  if (hasSmoothScroll) viewStorage.viewScroll.scroll.start()
  viewStorage.viewPrllx = new viewPrllx()
  viewStorage.viewInView = new viewInView()
  viewStorage.viewTextReveal = new viewTextReveal()
  viewStorage.modalContact = new modalContact()

  initForms({
    DOM: viewStorage.current
  })


}

export const onEnterCompleted = (to, from, trigger) => {

  if (isDEVMODE) console.log('onEnterCompleted')

  const { targetLocation } = globalStorage.taxi

  // Anchor
  if (targetLocation.hasHash) {
    const anchorEl = document.querySelector(window.location.hash)
    if (anchorEl) gsap.to(window, {duration: 1.8, scrollTo: { y: anchorEl, autoKill: false }, overwrite: 'all', ease: 'expo.inOut' })
  }

  // Analytics
  if (globalStorage.tarteaucitron && globalStorage.tarteaucitron.job.includes('gtag') && typeof gtag !== 'undefined') {
    gtag('config', globalStorage.tarteaucitron.user.gtagUa, {
      'page_path': targetLocation.pathname,
      'page_title': to.page.title,
      'page_location': targetLocation.href
    })
  }

}

export const onLeave = (from, trigger) => {

  // Remove Previous Page Effects
  const { hasInView, hasPrllx, hasTextReveal, hasModalContact } = viewStorage

  hasInView && viewStorage.viewInView.destroy()
  hasPrllx && viewStorage.viewPrllx.destroy()
  hasTextReveal && viewStorage.viewTextReveal.destroy()
  hasModalContact && viewStorage.modalContact.destroy()

  // LoadingClasses
  const { body } = domStorage
  body.classList.add('--loading')
  body.classList.add('--animating')

  /* const { menu } = domStorage
  const { menuOpen } = globalStorage

  // Close Menu
  menuOpen && globalStorage.closeMobileMenu()

  // Remove active links
  const { targetLocation } = globalStorage.taxi
  const navLinks = menu.querySelectorAll('.Nav__link')
  navLinks.forEach((link) => {
    if (link.href === targetLocation.raw) link.classList.add('--active')
    else link.classList.remove('--active')
  }) */

  FormsManager.destroy({
    DOM: viewStorage.current
  });

}
function initForms({DOM}){
  FormsManager.init({
    debug: isDEVMODE,
    clearOnSuccess: true,
    forms: {
      contact: {}
    }
  });
}
