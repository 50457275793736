import { isDEVMODE, viewStorage } from '../_globals'

export default class modalContact {

  constructor(container = viewStorage.current) {

    this.DOM = {
      btns: container.querySelectorAll('*[data-contactModal]'),
      modal: document.getElementById('ModalContact'),
      closeBtn : document.getElementById('ModalContact').querySelector('.Close')
    }

    if (this.DOM.btns.length === 0) return

    viewStorage.hasModalContact = true

    this.init()

  }

  init() {

    if (isDEVMODE) console.log('Init modalContact')

    this.addEvents();

  }

  addEvents() {
    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
    
    const { btns, closeBtn } = this.DOM
    btns.forEach((el) => el.addEventListener('click', this.openModal))
    closeBtn.addEventListener('click', this.closeModal)
  }

  openModal(e) {
    e.preventDefault()
    console.log('openModal')
    document.body.classList.add('--showModal')
    console.log(e.currentTarget.dataset.contactmodal)
  }

  closeModal(e) {
    e.preventDefault()
    document.body.classList.remove('--showModal')
  }

  destroy() {
    if (isDEVMODE) console.log('Destroy modalContact')
    const { btns, closeBtn } = this.DOM
    btns.forEach((el) => el.removeEventListener('click', this.openModal))
    closeBtn.removeEventListener('click', this.closeModal)

    viewStorage.hasModalContact = false

  }
}
